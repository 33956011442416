<template>
    <div class="d-flex content-center min-vh-100">
        <BContainer>
            <BRow class="justify-content-center">
                <BCol md="8">
                    <BCard class="text-left" footer-tag="footer" footer-bg-variant="light">
                        <div slot="header">
                            <strong><b-icon class="mr-1" icon="shield-lock-fill"/>간편 인증</strong>
                            <div class="card-header-actions">
                                <!-- <small class="text-muted">XCOST.ME, XCost 회원정보 변경을 위한 메일을 발송합니다.</small> -->
                            </div>
                        </div>

                        <BForm @submit="onSubmit">

                            <BFormGroup class="mt-3"
                                        label="비밀번호"
                                        label-for="passwordInput"
                                        description="게정 로그인 시 사용하시는 비밀번호를 입력해주세요">
                                <BInputGroup id="passwordInput">
                                    <BInputGroupPrepend is-text><BIconLockFill /></BInputGroupPrepend>
                                    <BFormInput v-model="userPassword"
                                                type="password"
                                                placeholder="비밀번호를 입력하세요"
                                                required
                                    />
                                </BInputGroup>
                            </BFormGroup>

                            <div class="text-right mt-3">
                                <BButton  variant="info" type="submit" :disabled="validPwdState">확인</BButton>
                            </div>

                        </BForm>

                    </BCard>


                </BCol>
            </BRow>
        </BContainer>


    </div>
</template>

<script>
    import {
        apiCall,
        cloneVar,
        alertError,
        alertWarn,
        warningAlertPageMove,
        alertSuccess,
        alertConfirm,
        checkBisNo,
        commify,
        toastSync
    } from '../../../common/utils';

    import DaumPostcode from 'vuejs-daum-postcode';
    import moment from "moment";

    export default {
        name: 'MemberPasswordVerify',
        props: ['memberToken'],
        data () {
            return {
                userPassword: '',
                userId: '',
                isSentUser: false,
                failCount: 0,
                isValidUser: false,
                isPwdChecked: false,
                userInfo: null,
                // pwdRegexp: /^(?=.*[0-9]+)[a-zA-Z][a-zA-Z0-9]{7,29}$/, // 영어+숫자(8자이상)
                pwdRegexp: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/, // 최소8자 영문,숫자,특수문자 조합
                emailRegexp: /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/
            }
        },
        async created(){
            console.log("-------- created: MemberFind -----" );
            try{
                this.isAuthLogin;
            }catch(err){
                console.log(err);
                await warningAlertPageMove( this.$bvModal, 'ERROR: '+err.message, '에러' );
                return;
            }
        },
        beforeMount() {

        },
        mounted(){
            console.log("-------- mounted: MemberFind -----  " );
        },

        computed: {
            isAuthLogin() {
                this.userInfo = this.$store.state.userStore.user;
                // if (!this.$store.state.userStore.isAuth) {
                //     this.$router.push( {path: '/service/login'} );
                // };
            },

            validPwdState(){
                if( this.userPassword.length >= 7 ) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        methods: {

            async onSubmit(e){
                e.preventDefault();

                try{
                    // console.log("this.userInfo --------------> ", this.userInfo);
                    // console.log("H_isAuthed --------------> ", $H_isAuthed);
                    // 로그인 검증 + 계정 비밀번호 검증
                    if(!$H_isAuthed) {
                        await warningAlertPageMove( this.$bvModal, '재로그인 후 시도 해주세요', '에러', '/#/service/login' );
                        return ;
                    }

                    const param = {
                        userId: this.userInfo.userId,
                        userPwd: this.userPassword,
                    }

                    const rs = await this.$store.dispatch('userStore/authPassword', param);
                    if(!rs) {
                        alertWarn( this.$bvModal, '비밀번호가 정확하지 않습니다', '입력오류' );
                        return ;
                    }

                    // 기존 회원정보 수정 폼 활용
                    const params = {
                        id: this.userInfo.userId,
                        no: this.userInfo.userNo,
                        hostName: window.location.hostname
                    }
                    const r = await apiCall('post', `/api/member/update-request`, params);
                    window.open(r.result.link);
                    this.$router.push( {path: '/'} );

                }catch(err){
                    console.log( err );
                    await warningAlertPageMove( this.$bvModal, 'ERROR: ' + err.message, '에러', '/main' );
                }
            },


        }

    }
</script>
